"use client";

import React from "react";
import Link from "next/link";

const NotFound: React.FC = () => {
  return (
    <main className="flex flex-col items-center justify-center min-h-screen">
      <div className="flex flex-col items-center justify-center text-center">
        <h2 className="text-2xl font-bold mb-2">
          Oops, we couldn&apos;t find that page!
        </h2>
        <p className="text-lg mb-4">
          The page you are looking for seems to be missing or may have been
          removed.
        </p>
        <Link
          href="/"
          className="bg-ginger-green text-white py-2 px-4 rounded-md"
        >
          Go back to the homepage
        </Link>
      </div>
    </main>
  );
};

export default NotFound;
